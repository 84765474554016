export default class ParameterSystem {
    constructor() {
        this.timeZone = { id: "", content: "" };
        this.isReplyCompany = false;
        this.replyName = "";
        this.replyTo = "";
    }
    update(data) {
        let content = this.timeZoneDisplay(data.timeZone.id);
        this.timeZone = { id: data.timeZone.id, content: content };
        this.isReplyCompany = data.isReplyCompany;
        this.replyName = data.replyName;
        this.replyTo = data.replyTo;
    }
    timeZoneDisplay(id) {
        if (id == "UTC-02")
            return "UTC-02:00 Fernando de Noronha"

        if (id == "E. South America Standard Time")
            return "UTC-03:00 Brasília"

        if (id == "Central Brazilian Standard Time")
            return "UTC-04:00 Amazônia"

        if (id == "SA Pacific Standard Time")
            return "UTC-05:00 Acre"

    }
}